import {
  CardContainer, ContentArea, Hero, HeroContent, HeroImage
} from 'components/styling/GeneralStyledComponents';
import { SmallLoader } from 'components/FullScreenLoader';
import { urlForImage } from 'utils/imageUtils';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useServices } from 'utils/hooks/useServices';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import CardItem from 'components/layout/CardItem';
import ContentContainer from 'components/layout/ContentContainer';
import FluidContainer from 'components/layout/FluidContainer';
import Grid from '@mui/material/Grid';
import Link from 'next/link';
import SanityGrid from 'components/sanity/SanityGrid';
import SanityHero from 'components/sanity/SanityHero';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const TopBar = styled.div`
  ${theme.breakpoints.down('sm')}{
    flex-direction: column;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  border-bottom: solid 2px #EFEFEF;
`;

/* TODO: Enable these paddings after the pilot */
const LeftSide = styled.div`
  ${theme.breakpoints.down('md')} {
    width: 60%;
    /* padding: ${theme.spacing(6, 3, 4, 3)}; */

  }
  ${theme.breakpoints.down('sm')}{
    width: 100%;
    /* padding: ${theme.spacing(3, 2)}; */
    border-right: none;
    border-bottom: solid 2px #EFEFEF;
  }
  width: 65%;
  /* padding: ${theme.spacing(6, 3, 4, 16.5)}; */
  display: flex;
  justify-content: flex-end;
  border-bottom: none;
`;

const PhoneService = styled.div`
  ${theme.breakpoints.down('md')} {
    width: 40%;
    padding: ${theme.spacing(6, 3, 8, 3)};
  }
  ${theme.breakpoints.down('sm')}{
    width: 100%;
    padding: ${theme.spacing(3, 2)};
    border-left: none;
  }
  width: 35%;
  padding: ${theme.spacing(6, 16.5, 4, 3)};
  border-left: solid 2px #EFEFEF;
`;

const TopBarHeader = styled(Typography)`
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(6, 3, 0, 3)};
  }
  ${theme.breakpoints.down('sm')}{
    font-size: 1.125rem;
    line-height: 1.25rem
    padding: ${theme.spacing(3, 2)};
  }
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.2rem;
  padding: ${theme.spacing(6, 3, 0, 16.5)};
`;

const Avatar = styled.img`
  ${theme.breakpoints.down('sm')}{
    width: 50px;
    height: 50px;
  }
  height: 85px;
  width: 85px;
`;

const Cards = styled.div`
  ${theme.breakpoints.down('xl')} {
    padding: ${theme.spacing(0, 4)};
    height: auto;
  }
  ${theme.breakpoints.down('md')}{
    position: static;
    padding: ${theme.spacing(2, 0)};
    margin-bottom: ${theme.spacing(4)};
  }
  ${theme.breakpoints.up('lg')} {
    height: fit-content;
  }
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
  top: -200px;
  padding: ${theme.spacing(0, 16.5)};
`;

const CardText = styled(Typography)`
  ${theme.breakpoints.down('md')}{
    color: #1e1e1e;
    font-size: 1.25rem;
    padding-left: ${theme.spacing(2)};
  }
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
`;

const DisabledButton = styled(Button)`
  color: #fff;
  background-color: #c2c2c2;
`;

export const DisabledTextField = styled(TextField)`
& label.Mui-focused {
  color: white;
}
& .MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color: #d6d6d6;
  }
}
`;

interface FrontPageProps {
  doc: any;
  docs: any;
  featuredBlog?: any;
  phone: any;
}

const FrontPage = ({
  doc,
  docs,
  featuredBlog,
  phone,
}: FrontPageProps) => {
  const {
    t,
  } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const router = useRouter();

  const {
    areServicesLoading,
    latestServices,
    getServiceMinimumPrice,
    getHumanReadableServiceCategory,
  } = useServices();

  const handleViewAllClick = useCallback(() => {
    router.push('/services');
  }, [router,]);

  return <>

    {/* Topbar */}
    <FluidContainer>
      <TopBar>
        <LeftSide>

          <Grid container rowGap={isMobile ? 1 : 3}>
            <TopBarHeader>
              {doc.tacticalMessageTitle}
            </TopBarHeader>

            <Grid container
              alignItems={'center'}
              rowGap={isTablet ? 2 : 0}
              paddingLeft={isMobile ? 2 : isTablet ? 3 : 16.5}
              paddingRight={isMobile ? 2 : 3}
              paddingTop={3}
              paddingBottom={isTablet ? 3 : 4}
              bgcolor={'#f8f8f8'}>

              <Grid item lg={6} xs={12}>
                <Typography variant='body1' color={'#c2c2c2'}>
                  {doc.tacticalMessageText}
                </Typography>
              </Grid>

              <Grid container item
                columnGap={1}
                lg={6}
                xs={12}>
                <Grid item xs={8}>
                  <DisabledTextField
                    placeholder='Katuosoite'
                  />
                </Grid>
                <Grid item>
                  <DisabledButton variant='contained'>
                    {t('Show')}
                  </DisabledButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LeftSide>

        {/* Phone Service */}
        <PhoneService>
          <Grid container rowGap={2}>
            <Grid item sm={12}>
              <Typography variant='body1'>
                {t('Our phone service will help you')}
              </Typography>
              <Typography variant='body1'>
                {phone.phoneServiceDays} {phone.phoneServiceTimes}
              </Typography>
            </Grid>
            <Grid container alignItems={'center'} columnGap={2}>
              <Grid item>
                {
                  phone.phoneServiceAvatar ?
                    <Avatar
                      src={urlForImage(phone.phoneServiceAvatar.image).url()}
                      alt={phone.phoneServiceAvatar.alt}
                    /> :
                    <Avatar
                      src='/images/logos/placeholder_images/asiakaspalvelu.png'
                      alt='customer service avatar' />
                }
              </Grid>
              <Grid item>
                <Typography variant='body2'>
                  {phone.phoneServiceNumber}
                </Typography>
                <Typography variant='body1'>
                  (mpm)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </PhoneService>
      </TopBar>
    </FluidContainer>

    {/* Hero */}
    <FluidContainer>
      <SanityHero data={doc.hero} />
    </FluidContainer>

    {/* Newest services */}
    {areServicesLoading ?
      <SmallLoader /> :
      <Cards>
        <ContentArea>
          <CardText>
            {t('New')}
          </CardText>
          <CardContainer>
            {latestServices.map((service) => {
              const sanityServiceDoc = docs.find((doc) => service.sanityId === doc._id);
              return <Grid item key={service.id}>
                <CardItem
                  slug={sanityServiceDoc.slug?.current}
                  imageUrl={service.imageUrl.length ? service.imageUrl : undefined}
                  title={service.name}
                  subtitle={getHumanReadableServiceCategory(service)}
                  text={service.shortDescription}
                  price={getServiceMinimumPrice(service.id)}
                  id={service.id}
                  discount={service.pricingModel.isDiscountPrice}
                />
              </Grid>;
            })}
          </CardContainer>
          <Grid container justifyContent={'center'}>
            <Grid item>
              <Button variant='outlined' onClick={handleViewAllClick}>
                {t('Show all')}
              </Button>
            </Grid>
          </Grid>
        </ContentArea>
      </Cards>}

    {/* Why Block */}
    <FluidContainer withPadding mobileWithPadding
      style={{
        backgroundColor: '#F8F8F8',
      }}>
      <Grid container marginTop={isMobile ? '0px' : '-140px'}>
        <SanityGrid header={t('Why Block?')} content={doc.whyContent} />
      </Grid>

    </FluidContainer>

    {/* Secondary Hero */}
    <ContentContainer>
      <SanityHero data={doc.secondaryHero} />
    </ContentContainer>

    {/* Featured blog */}
    <FluidContainer mobileWithPadding withPadding
      style={{
        backgroundColor: '#FCFBFA',
        marginTop: '60px',
      }}>
      {
        featuredBlog ?
          <Hero>
            <HeroImage src={urlForImage(featuredBlog.mainImage.image).url()} alt={featuredBlog.mainImage.alt} />
            <HeroContent>
              <Grid container rowGap={isMobile ? 1 : 2}>
                <Grid item xs={12}>
                  <Typography variant='h4'>
                    {t('Featured')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h1' fontSize={isMobile ? '0.9375rem' : 'auto'}>
                    {featuredBlog.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' fontSize={isMobile ? '0.8125rem' : 'auto'}>
                    {featuredBlog.subtitle}
                  </Typography>
                </Grid>
                <Grid item>
                  <Link href="/blog/[slug]" as={`/blog/${featuredBlog?.slug.current}`}>
                    <Button variant='contained' color='secondary'>
                      {t('Read more')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </HeroContent>
          </Hero> :
          null
      }
    </FluidContainer>

    {/* TODO: Enable this after the Pilot */}
    {/* Join email list*/}
    {/* <FluidContainer>
      <JoinEmailList/>
    </FluidContainer> */}

  </>;
};
export default FrontPage;
