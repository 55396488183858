/* eslint-disable new-cap */
import * as React from 'react';
import { CardContentContainer, CardSpecial, DiscountPrice, DiscountSpecial } from 'components/styling/GeneralStyledComponents';
import { DEFAULT_MINIMUM_PRICE } from 'utils/hooks/useServices';
import { MouseEventHandler, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Dinero from 'dinero.js';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledCard = styled(Card)`
  box-shadow: 0px 8px 30px #0000000D;
  min-width: 215px;
  max-width: 255px;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
`;

interface ServiceCardProps {
  slug?: string;
  badgeText?: string;
  imageUrl?: string;
  title?: string;
  subtitle?: string;
  text?: string;
  price?: number;
  discount?: number | boolean;
  id?: string;
  cardOnClick?: (id?: string) => MouseEventHandler<HTMLDivElement>;
}

// TODO: replace placeholder data
const ServiceCard = ({
  slug,
  badgeText,
  imageUrl,
  title,
  subtitle,
  text,
  price,
  discount,
  id,
}: ServiceCardProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();
  const {
    t,
  } = useTranslation();

  const handleOnClick = React.useCallback(() => {
    router.push({
      pathname: `/service/${slug}`,
      query: {
        id,
      },
    }
    );
  }, [router, slug, id,]);

  const imageStyle = useMemo(() => {
    return {
      height: isMobile ? '215px' : '255px',
      width: isMobile ? '215px' : '255px',
      objectFit: 'cover',
      objectPosition: '0 100%',
    };
  }, [isMobile,]);

  return <>
    <StyledCard onClick={handleOnClick}>
      <CardSpecial>{badgeText || 'tutustu'}</CardSpecial>
      {discount && <DiscountSpecial>ALE 50 %</DiscountSpecial>}
      <CardMedia
        sx={imageStyle}
        component="img"
        image={`${imageUrl || '/images/logos/placeholder_images/kotisiivous.jpg'}`}
        alt="placeholder image"
        onClick={handleOnClick}
      />

      <CardContentContainer>
        <CardContent>
          <TitleContainer>
            <Typography gutterBottom variant="h6" component="div" fontWeight={500}>
              {title || 'Kotisiivous'}
            </Typography>
          </TitleContainer>

          <Grid container height={isMobile ? '140px' : '125px'}>
            <Typography gutterBottom variant="subtitle1" component="div" paddingTop={1}>
              {subtitle || 'Siivous'}
            </Typography>
            <Typography variant="body1" >
              {text || 'Lorem ipsum dolor sit amet, ipsum labitur lucilius aute.'}
            </Typography>
          </Grid>

        </CardContent>
        <Divider variant='fullWidth'></Divider>

        <CardActions sx={{
          alignItems: 'center',
          gap: '0 !important',
          height: isMobile ? '24px' : 'auto',
        }}>
          {/*  TODO: remove when massage discount is no more
          Päivi 31.10.2022 */}
          {discount ?
            <Stack
              flex={1}
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent='space-between'>
              <Typography
                className='price cardActions-text' paragraph variant="h6" component="div">
                alk.
                <DiscountPrice>
                  {Dinero({
                    amount: price ?? DEFAULT_MINIMUM_PRICE, currency: 'EUR',
                  }).setLocale('fi-FI').toFormat('0,0.00')} €
                </DiscountPrice> / krt
              </Typography>
              <Typography
                sx={{
                  fontSize: '11px',
                  marginTop: isMobile ? '-10px' : 0,
                }} paragraph variant="body1" component="div">
                (norm. {Dinero({
                  amount: price * 2, currency: 'EUR',
                }).setLocale('fi-FI').toFormat('0,0')} €)
              </Typography>
            </Stack> :

            <Typography className='price cardActions-text' paragraph variant="h6" component="div">
              alk. {Dinero({
                amount: price ?? DEFAULT_MINIMUM_PRICE, currency: 'EUR',
              }).setLocale('fi-FI').toFormat('0,0.00')} € / krt
            </Typography>}
          <ArrowForwardIcon color='secondary' />
        </CardActions>
      </CardContentContainer>
    </StyledCard>
  </>;
};

export default ServiceCard;
